<template>
  <section v-if="data" class="o-hub-default">
    <div
      class="o-hub-default__container container"
      :class="{ 'o-hub-default__container--grid': isHaveCategories }"
    >
      <div v-if="isHaveCategories" class="o-hub-default__filters">
        <div class="o-hub-default__filters-item">
          <h4 class="o-hub-default__filters-title">Категории</h4>

          <a
            v-for="(element, idx) in data.categories"
            :href="element.link"
            class="link-item link-item--rounded"
            :class="{
              ['o-hub-default__filters-point--hidden']: isVisibleItem(idx, data.categories),
              ['link-item--current']: element.active,
            }"
          >
            {{ element.title }}
          </a>

          <div v-if="isVisibleButton" class="o-hub-default__item-more">
            <Button
              css-class="button-icon-text"
              icon-pos="right"
              modifier="primary"
              @click="toggleVisibleItems(data.categories)"
            >
              <template #icon>
                <i
                  :class="
                    mapShowMoreState.get(data.categories)?.visibleItems === 9999
                      ? 'icon-main-up'
                      : 'icon-main-down'
                  "
                ></i>
              </template>

              {{ mapShowMoreState.get(data.categories)?.state ? 'Свернуть' : 'Показать все' }}
            </Button>
          </div>
        </div>

        <div class="o-hub-default__filters-item-mobile">
          <Dropbox
            ref="dropboxRef"
            v-model="dropboxState"
            v-on-click-outside="onClickOutsideHandler"
            :disabledAutoPosition="disableState"
          >
            <template #activator="{ toggle, close, opened }">
              <Button
                :selected="opened"
                class="button--quaternary button--small"
                icon-pos="left"
                @click="
                  toggle();
                  toggleBodyScroll();
                "
              >
                <template #icon>
                  <i class="icon-service-menu"></i>
                </template>
                Категории
              </Button>

              <div
                class="dropbox-overlay"
                @click="
                  close();
                  toggleBodyScroll();
                "
              ></div>
            </template>

            <template #header="{ close }">
              <Button
                aria-disabled="true"
                aria-label="Navigation"
                class="o-hub-default__dropbox-back"
                css-class="button-navigation"
              >
                <i class="icon-main-prev"></i>
              </Button>

              <div class="dropbox-title">Категории</div>

              <Button
                aria-label="Закрыть окно"
                css-class="button-navigation"
                @click="
                  close();
                  toggleBodyScroll();
                "
              >
                <i class="icon-main-close"></i>

                <template #loading>
                  <Loader theme="small" />
                </template>
              </Button>
            </template>

            <div class="o-hub-default__dropbox-items">
              <a
                v-for="element in data.categories"
                :href="element.link"
                class="link-item"
                :class="{
                  ['link-item--current']: element.active,
                }"
              >
                {{ element.title }}
              </a>
            </div>
          </Dropbox>
        </div>
      </div>

      <div class="o-hub-default__content">
        <h3 class="o-hub-default__content-title">{{ data.title }}</h3>

        <div class="o-hub-default__content-wrapper">
          <template v-for="(card, i) in data.items" :key="i">
            <Card
              :characters="{
                all: card.contentLength,
                visible: 0,
              }"
              css-class="m-card-review"
            >
              <template #contentHeader>
                <Tag href="#" tag="span" theme="light">Вопрос №{{ card.number }}</Tag>
                <div class="m-card-review__heading">
                  {{ card.name }}
                  <span>{{ card.city }}, {{ card.date }}</span>
                </div>
              </template>

              <template #content>
                <div v-html="card.content"></div>
              </template>

              <template #contentFooter>
                <Button
                  :href="card.link"
                  modifier="quaternary"
                  size="small"
                  tag="a"
                  target="_blank"
                >
                  Читать далее
                </Button>
              </template>
            </Card>
          </template>
        </div>
      </div>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script lang="ts" setup>
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Button from '@ice-products-ui/vue-library/Button';
import Card from '@ice-products-ui/vue-library/Card';
import Tag from '@ice-products-ui/vue-library/Tag';
import Dropbox from '@ice-products-ui/vue-library/Dropbox';
import Loader from '@ice-products-ui/vue-library/Loader';

// Composables
import useGetData from '~/composables/api/useGetData';
import useScroll from '~/composables/dom/useScroll';
import { useDropboxHub, useHubSidebarVisibleItems } from '~/components/O/Hub/OHub.composables';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Directives
import vOnClickOutside from '@ice-products-ui/vue-library/OnClickOutside';

// Types
import type { THubDefault } from '~/components/O/Hub/OHub.types';
import type { IComponentProps } from '~/typings/types/component.types';
import type { OTHubFaq } from '~/components/O/Hub/FAQ/OHubFAQ.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Schema
import schema from '~/components/O/Hub/FAQ/OHubFAQ.schema';

defineOptions({
  name: 'OHubFAQ',
});

const props = withDefaults(defineProps<IComponentProps<THubDefault<OTHubFaq>>>(), {
  viewName: 'OHubFAQ',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { getComponent } = useGetData<THubDefault<OTHubFaq>>();
const { dropboxRef, onClickOutsideHandler, dropboxState, disableState, toggleBodyScroll } =
  useDropboxHub();
useScroll(0, 'header-scrolled', 1000);

const data = ref<THubDefault<OTHubFaq>>(getComponent(props).component);

const { mapShowMoreState, isVisibleItem, isVisibleButton, toggleVisibleItems } =
  useHubSidebarVisibleItems<OTHubFaq>(data.value.categories);
useComponentSchemaValidator(schema, props);

const isHaveCategories = computed(() => data.value.categories.length);
</script>
